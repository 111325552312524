import React from 'react';
import {
  Link
} from "react-router-dom";

const Nav = () => {

  return (
    <div className='Nav'>
      <span>[<Link to='/nacs/'>NACS - Open</Link>]</span>
      <span>[<Link to='/wnacs/'>WNACS - Women's</Link>]</span>
      {/* <span>[<Link to='/test/'>TEST</Link>]</span> */}
    </div>
  );
}

export default Nav;