

// calculate strength
function calculateStrength(finalists, system, cutLine) {
  //console.log("+++calculateStrength");
  if (!(finalists?.length > 0)) return false;

  // cut to cut line
  let currentField = finalists.slice(0, cutLine);

  // calculate rating and ranking values
  let rating = 0;
  let ranking = 0;
  let ratedPlayerCount = 0;
  currentField.forEach((p, i) => {
    let a = Math.max(0, (p.stats.system[system].ratings_value * 0.000546875) - 0.703125);
    let b = Math.max(0, Math.log(p.stats.system[system].current_rank) * -0.211675054 + 1.459827968);

    if (p.stats.system[system].current_rank < 1) {
      b = 0;
    }

    //if (p.stats.system.womens.total_events_all_time > 5) {
    ratedPlayerCount++
    //}

    rating += a;
    ranking += b;
  });

  let tgp_options = [0, 0, 32, 44, 48, 56, 60, 68, 68, 72, 76, 76, 80, 84, 84, 88, 88, 92, 92, 92, 96, 96, 96, 100];

  //console.log(".........>TGP options", tgp_options.length, tgp_options[finalists.length]);

  let tgp = tgp_options[finalists.length];

  // let tgp = 100;
  // switch (cutLine) {
  //   case 8:
  //     tgp = 68;
  //     break;
  //   case 16:
  //     tgp = 88;
  //     break;
  //   default:
  //     tgp = 100;
  // }

  let booster = 150;
  let base = ratedPlayerCount * 0.5;
  let value = base + rating + ranking;

  let str = {
    rating: rating,
    ranking: ranking,
    base: base,
    tgp: tgp,
    value: value,
    booster: booster,

    tourValue: (booster * tgp * value / 10000).toFixed(2)
  };
  //console.log("/////////////// str", str);
  return str;
}

export default calculateStrength;