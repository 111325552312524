import React from 'react';
import WPlayer from './WPlayer';

const WPlayers = ({ players, disabled, loading, finalists, selectedRegion, cutLine_w, cutLine_o, toggleDisabled, openOnly, setOpenOnly }) => {

  //const hideAboveCutLine = true;

  let pos_w = 0;
  let pos_o = 0;

  let cutLine_drawn_o = 0;
  let cutLine_drawn_w = 0;

  return (
    <div className="WPlayers">
      <>
        {players.length < 1 ?
          (loading ? <p><img alt="Loading..." src='/loading.svg' width='100' /></p> : <p>Select a region...</p>) : (
            <>
              <div className={"Top100 Womens" + (openOnly ? " NotCounted" : "")}>
                <h2>Top 100 Women's</h2>
                {openOnly ? <h4 className='warning'>** NOT COUNTED (less than 2 events)**</h4> : null}
                {
                  players.top100_w.map(p => {

                    let dis = disabled.has(p.player_id);
                    let other = finalists.find(f => f.player_id === p.player_id && f.method === 'WNACSO');
                    return (
                      <React.Fragment key={`${p.player_id}_wrapper`}>
                        <dl key={p.player_id}>
                          <WPlayer player={p} pos={dis || other ? null : ++pos_w} dis={dis} other={other} otherMark='-O-' selectedRegion={selectedRegion} toggleDisabled={toggleDisabled} />
                        </dl>
                        {pos_w === cutLine_w && !cutLine_drawn_w++ ? <div style={{ clear: "both" }} className="CutLine">------ CUT LINE ------</div> : null}
                      </React.Fragment>
                    )
                  })}
              </div>
              <div className="Top100 Open">
                <h2>Top 100 Open</h2>
                {
                  players.top100_o.map(p => {
                    let dis = disabled.has(p.player_id);
                    let other = finalists.find(f => {
                      return f.player_id === p.player_id && f.method === 'WNACSW'
                    });
                    return (
                      <React.Fragment key={`${p.player_id}_wrapper`}>
                        <dl key={p.player_id}>
                          <WPlayer player={p} pos={dis || other ? null : ++pos_o} dis={dis} other={other} otherMark='-W-' selectedRegion={selectedRegion} toggleDisabled={toggleDisabled} />
                        </dl>
                        {pos_o === cutLine_o && !cutLine_drawn_o++ ? <div style={{ clear: "both" }} className="CutLine">------ CUT LINE ------</div> : null}
                      </React.Fragment>
                    )
                  })}
              </div>
            </>
          )}
      </>
    </div >
  )
}

export default WPlayers;