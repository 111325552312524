import React from 'react';
import Player from './Player';

const Players = ({ players, disabled, loading, defaultCutLine, cutLine, prize_fund, selectedRegion, toggleDisabled, setCutLine }) => {

  let pos = 0;

  return (
    <div className="Players">
      <>
        {players.length < 1 ?
          (loading ? <p><img alt="Loading..." src='/loading.svg' width='100' /></p> : <p>Select a region...</p>) : (
            <>
              <h2>Top 100 (Qualifiers: {cutLine}, Prize Pool: {prize_fund})</h2>
              <h4>Override bracket size / move cut line: (default: {defaultCutLine})&nbsp;
                <select onChange={(e) => { setCutLine(parseInt(e.target.value)) }} value={cutLine}>
                  <option value={8}>8</option>
                  <option value={16}>16</option>
                  <option value={24}>24</option>
                </select>
              </h4>
              {players.top100.map(p => {

                let dis = disabled.has(p.player_id);
                return (
                  < React.Fragment key={`${p.player_id}`}>
                    <dl>
                      <Player player={p} dis={dis} toggleDisabled={toggleDisabled} selectedRegion={selectedRegion} pos={dis ? null : ++pos} />
                    </dl>
                    {pos === cutLine ? <div style={{ clear: "both" }} className="CutLine">------ CUT LINE ------</div> : null}
                  </React.Fragment>
                )
              })}
            </>
          )}
      </>
    </div >
  )
}

export default Players;