import PlayerLocation from './PlayerLocation';

const Player = ({ player, dis, toggleDisabled, selectedRegion, pos }) => {

  let p = player;
  return (
    <>
      <dt className={dis ? "disabled" : ""}>{
        dis ? "---" :
          <span className="PlayerRank">#{pos}</span>} <a
            href={`https://www.ifpapinball.com/players/view.php?p=${p.player_id}`}
            rel='noreferrer' target='_blank'>{p.player_name}</a> (
        <PlayerLocation city={p.city} state={p.stateprov_code} />
        ) - {p.wppr_points} (Events: {p.event_count}, Rating: {p.stats.system.open.ratings_value})
      </dt>
      <dd>
        {p?.rep?.NACS ?
          <span className={"StateRep" + (dis ? " disabled" : "")}
            title={`NACS Rep for ${p.rep.NACS}`}
            onClick={() => toggleDisabled(p.player_id)}>{p.rep.NACS}</span>
          : null
        }
        {p?.rep?.WNACS ?
          <span className={"StateRep" + (dis ? " disabled" : "")}
            title={`NACS Rep for ${p.rep.NACS}`}
            onClick={() => toggleDisabled(p.player_id)}>{p.rep.WNACS}</span>
          : null
        }
        {p.series.map((i) => {
          return (
            (i.series_rank <= i.field_size && i.region_code !== selectedRegion) ?
              <span key={`${p.player_id}-${i.region_code}`} className={"StateRank" + (dis ? " disabled" : "")}
                onClick={
                  () => toggleDisabled(p.player_id)
                }>{i.region_code} #{i.series_rank} ({i.field_size} - {i.prize_fund})</span>
              : null
          )
        })}
        <span className={"StateRank" + (dis ? " disabled" : "")} onClick={
          () => toggleDisabled(p.player_id)
        }>Decline {selectedRegion}</span>
      </dd>
    </>
  )
}

export default Player;
