import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Nav from './Nav';
import App from './App';
import Womens from './Womens';
import WomensTest from './WomensTest';
import ReactGA from 'react-ga4';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

ReactGA.initialize([
  {
    trackingId: "G-JBYPY4YQDS",
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Nav />} />
        <Route path="/wnacs/" element={<Womens />} />
        <Route path="/nacs/" element={<App />} />
        <Route path="/test/" element={<WomensTest />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);