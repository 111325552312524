

function calculateDataExport(finalists, cutLine, disabled) {
  //console.log('++++construct export set', finalists.length);

  if (!finalists.length > 0) return [];

  let es = [];
  let index = 0;


  //console.log("++cutLine", cutLine);
  while (es.length < cutLine
    && index < finalists.length) {
    let p = finalists[index];
    //console.log("++cl-p", es.length, p);
    if (!disabled.has(p.player_id)) {
      es.push(p.player_id);
    }
    index++;
  }

  //console.log("++++es ", es.length, es)
  return es;
}

export default calculateDataExport;