import React from 'react';
import PlayerLocation from './PlayerLocation';

const Finalists = ({ finalists, prize_pool }) => {

  return (
    <div className="Finalists">
      <h3>Finalists (Qualifiers: {finalists.length}, Prize Pool: {prize_pool})</h3>
      <div>
        <ul>
          {finalists.map((p, i) => {
            return (
              <React.Fragment key={p.player_id}>
                <dl>
                  <dt>
                    #{i + 1} {(p.method === 'WNACSW' ? 'W' : 'O')} <a
                      href={`https://www.ifpapinball.com/players/view.php?p=${p.player_id}`} rel='noreferrer'
                      target='_blank'>{p.player_name}</a> (<PlayerLocation city={p.city} state={p.stateprov_code} />) - {p.wppr_points} (Events: {p.event_count}, Rating: {p.stats.system.womens.ratings_value})
                  </dt>
                </dl>
                {/* {i === cutLine - 1 ? <p>--- CUT LINE ---</p> : null} */}
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </div >
  )
}

export default Finalists;