import React from 'react';

const Export = ({ exportSet, dataExport, setExportSet, onlyAboveCutLine }) => {

  const [show, setShow] = React.useState(false);

  return (
    <div className="Export">Export
      <select onChange={(e) => setExportSet(e.target.value)}>
        <option value="cut">Above Cut Line (not declined)</option>
        {onlyAboveCutLine ? null : <>
          <option value="all"> All (including declined)</option>
          <option value="top32">Top 32 (not declined)</option>
        </>}
      </select>

      <button onClick={(e) => { navigator.clipboard.writeText(dataExport.join('\r\n')); }}>Copy</button>

      {show ? <span onClick={() => setShow(false)}>(hide)</span> : <span onClick={() => setShow(true)}>(show)</span>}
      {show ? <textarea disabled className={exportSet} value={dataExport.length ? dataExport.join('\r\n') : null} /> : null}
    </div>
  );
}

export default Export;