import React from 'react';

const Todos = ({ version }) => {

  return (

    <div className="Todos">
      <h3>Todo (ver {version})</h3>
      <ul>
        <li className="done">DONE - Open: Allow for cut line speculation</li>
        <li>Show the combined Women's ranking for other states in Top 100 lists</li>
        <li>Toggle whether women's side is counted for WNACS selection</li>
        <li className="done">DONE: Review: women's prize pool logic</li>
        <li className="done">DONE: Review: women's other states field size</li>
        <li>Toggle ability to see if players are close in another state, rather than already above the cut line</li>
        <li>Add prize money breakdown somewhere</li>
        <li>Show a bracket</li>
        <li>Women's: toggle hidding players above the line</li>
        <li className="done">FIXED - DEFECT: Adjust TGP for odd field sizes</li>
        <li className="done">DONE - Women's version</li>
        <li className="done">FIXED - DEFECT: when no women's events, only 8 qualify from WNACSO</li>
        <li className="done">DONE - Review: women's export</li>
        <li className="done">DONE - Show Region reps</li>
        <li className="done">DONE - Show tournament count per player</li>
        <li className="done">DONE - Save/Share spectulations</li>
        <li className="done">DONE - Export player IDs</li>
        <li className="done">DONE - Show tournament value</li>

        <li>Questions? Feedback? Hate mail? Join on <a href="https://discord.gg/JM4JsH9C">Discord</a></li>
      </ul>
    </div>

  );
}

export default Todos;