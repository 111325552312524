import './App.css';
import Regions from './Regions';
import Tips from './Tips';
import Export from './Export';
import Todos from './Todos';
import Strength from './Strength';
import Nav from './Nav';
import React, { useState, useEffect } from 'react';
import WPlayers from './WPlayers';
import Finalists from './Finalists';
import { useLocation, useNavigate } from 'react-router-dom';
import calculateStrength from './CalculateStrength';
import calculateFinalists from './CalculateFinalists';
import calculateDataExport from './CalculateDataExport';

function App() {
  const [regions, setRegions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [cutLine_w, setCutLineW] = useState(0);
  const [cutLine_o, setCutLineO] = useState(0);
  const [cutLine, setCutLine] = useState(0);
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState("?");
  const [error, setError] = useState(null);
  const [dataExport, setDataExport] = useState([]);
  const [exportSet, setExportSet] = useState('cut');
  const [strength, setStrength] = useState(false);
  const [finalists, setFinalists] = useState([]);

  const [disabled, setDisabled] = useState(new Set());

  const hash = useLocation();
  const navigate = useNavigate();

  // get data out of the hash
  useEffect(() => {
    let hashFragments = hash.hash.replace("#", "").split("/");
    console.log('++++ hashFragments', hashFragments);

    if (hashFragments.length > 1) {
      if (hashFragments[1] !== selectedRegion) {
        let reg = hashFragments[1].toUpperCase();
        if (reg !== selectedRegion) {
          console.log(`+++ region change from ${selectedRegion} to ${reg}`);

          // reset all the things
          setError(null);
          setDisabled(new Set());
          setDataExport([]);
          setPlayers([]);
          setStrength(false);
          setFinalists([]);

          setSelectedRegion(reg);
        }
      }
      if (hashFragments[2] !== undefined) {
        let x = hashFragments[2].split(",");
        let dis = new Set();
        x.forEach(p => {
          dis.add(p);
        });
        setDisabled(dis);
      }
    }

    fetch('/api/version')
      .then(response => response.json())
      .then(data => setVersion(data.version));
  }, [hash, selectedRegion]);

  // load regions
  useEffect(() => {
    //console.log('+++loadRegions');
    fetch('/api/regions/NACS')
      .then(response => response.json())
      .then(data => setRegions(data.active_regions));
  }, []);



  // load players when selectedRegion changes
  useEffect(() => {
    if (selectedRegion === null) return;

    const fetchData = async () => {
      console.log('++++fetchData', selectedRegion, players);
      setLoading(true);
      await fetch(`/api/top100/WNACS/${selectedRegion}`)
        .then(response => {
          if (response.status === 400) {
            throw new Error(response.statusText);
          }
          setError(null);
          return response.json();
        })
        .then(data => setPlayers(data))
        .catch(err => {
          console.log(err);
          setError(err.toString());
          setLoading(false);
        });
    };

    fetchData();
  }, [selectedRegion]);


  // perform calculations when players are loaded
  useEffect(() => {
    if (!(players?.top100_w?.length > 0)) return;

    setLoading(false);
    setCutLine(players.stats.yearly_stats.field_size);
    console.log('++++cutLine', cutLine);

    /** @TODO */
    // let prize_fund = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: 'USD'
    // }).format(players.stats.yearly_stats.prize_fund);
    // setPrizeFund(prize_fund);


    const calculate = () => {
      console.log('***** calculate', players);
      const finalists = calculateFinalists(players, cutLine, setCutLineW, setCutLineO, disabled);
      setFinalists(finalists);
      setDataExport(calculateDataExport(finalists, cutLine, disabled, exportSet));
      setStrength(calculateStrength(finalists, 'womens', cutLine));
    };
    calculate();
  }, [players, cutLine, disabled, exportSet]);


  // toggle disabled players
  const toggleDisabled = (playerId) => {
    console.log('+++toggleDisabled', playerId);
    const updated = structuredClone(disabled);

    if (updated.has(playerId)) {
      updated.delete(playerId);
    } else {
      updated.add(playerId);
    }
    //setDisabled(updated);

    let updatedArray = Array.from(updated);
    let newHash = `#/${selectedRegion}/${updatedArray.join(",")}`;
    navigate(newHash, { replace: true });
  }


  // select new region
  function selectNewRegion(region) {
    navigate(`#/${region}`, { replace: true });

  };

  return (
    <div className="App">

      <Nav />

      <header className="App-header">
        <h1>TEST WNACS Standings</h1>
      </header>

      <Tips />

      <Regions series="WNACS" regions={regions} selectedRegion={selectedRegion}
        selectNewRegion={selectNewRegion} nav={navigate} />

      {error ? <div className="Error">{error}</div> : null}

      {dataExport.length ? <Export exportSet={exportSet} dataExport={dataExport}
        setExportSet={setExportSet} allEnabled={false} /> : null}

      {strength && <Strength strength={strength} />}

      {selectedRegion && finalists.length > 0 &&
        <Finalists finalists={finalists} />}

      {selectedRegion &&
        <WPlayers
          players={players}
          disabled={disabled}
          loading={loading}
          finalists={finalists}
          cutLine_w={cutLine_w}
          cutLine_o={cutLine_o}
          selectedRegion={selectedRegion}
          toggleDisabled={toggleDisabled}
        />
      }

      <Todos version={version} />

    </div >
  );
}

export default App;
