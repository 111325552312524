
/// http://localhost:4000/wnacs/#/ME/119755,119830,64122

// calculate finalists list
function calculateFinalists(players, cutLine, setCutLineW, setCutLineO, disabled, openOnly) {
  //console.log("++++++++++++calculate finalists", players?.top100_w?.length, players?.top100_o?.length);
  const pl = [];
  const used = new Set();

  let cutLine_w = 0;
  let cutLine_o = 0;

  let side_w = structuredClone(players.top100_w);
  let side_o = structuredClone(players.top100_o);

  let side = 'WNACSW';

  if (openOnly) {
    side = 'WNACSO';
  }

  let i = 0;

  ////////////////////////////////////////////////////////////////////////
  while (pl.length < cutLine && i++ < 64) {
    let p = getNextFinalist(side, side_w, side_o, used, disabled);
    if (p) {
      used.add(p.player_id);
      pl.push(p);

      if (p.method === 'WNACSW') {
        cutLine_w++;
      } else {
        cutLine_o++;
      }
    }

    if (side === 'WNACSW') {
      side = 'WNACSO';
    } else {
      side = 'WNACSW';
      if (openOnly) {
        side = 'WNACSO';
      }
    }
  }
  ////////////////////////////////////////////////////////////////////////

  setCutLineO(cutLine_o);
  setCutLineW(cutLine_w);

  return pl;
}

const getNextEligiblePlayer = (list, used, disabled) => {

  do {
    let player = list.shift();
    if (!player) { return false; }
    if (!disabled.has(player.player_id) && !used.has(player.player_id)) {
      return player;
    }
  } while (list.length > 0);
  return false;
}

const getNextFinalist = (side, side_w, side_o, used, disabled) => {

  if (side === 'WNACSW') {
    return getNextEligiblePlayer(side_w, used, disabled);
  } else {
    return getNextEligiblePlayer(side_o, used, disabled);
  }
}

export default calculateFinalists;
