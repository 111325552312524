

const PlayerLocation = ({ city, state }) => {

  let trimmed = `${city} ${state}`.trim();

  let final = trimmed.length > 0 ? trimmed : "-";

  return (
    <>{final}</>
  );
}

export default PlayerLocation;